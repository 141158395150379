<template>
    <div class="p-4  animate-pulse">
        <div class="flex flex-col space-y-4">
            <!-- Video Thumbnail Placeholder -->
            <div class="w-full h-48 bg-gray-700 rounded-lg"></div>

            <div class="flex space-x-4">
                <!-- Channel/Profile Picture Placeholder -->
                <div class="w-12 h-12 bg-gray-700 rounded-full"></div>

                <div class="flex-1">
                    <!-- Video Title Placeholder -->
                    <div class="h-4 bg-gray-700 rounded w-2/3 mb-2"></div>
                    <!-- Video Stats Placeholder -->
                    <div class="h-3 bg-gray-700 rounded w-1/3 mb-2"></div>
                    <!-- Channel Name Placeholder -->
                    <div class="h-3 bg-gray-700 rounded w-1/4"></div>
                </div>
            </div>
        </div>
    </div>
</template>
